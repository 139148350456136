<template>
	<div class="layout">
		<div class="main">
			<keep-alive>
				<router-view v-if="$route.meta.keepAlive"></router-view>
			</keep-alive>
			<router-view v-if="!$route.meta.keepAlive"></router-view>
		</div>
	</div>
</template>
<script>
	export default {
		data(){
			return {
			}
		},
		computed:{
		},
		mounted(){
			
		},
		methods:{
			
		},
		beforeDestroy() {
			
		},
	}
</script>
<style lang="scss" scoped>
	.layout{
		height: 100%;width:100%;display: flex;
		.main{
			width: 100%;height: 100%;background-color: #F4F7FF;
		}
	}
</style>
